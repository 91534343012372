// section chiusura del ciclo nucleare
.scheda-centrale {
  background: $s-lightgray;

}

.scheda-centrale {
	background: white;

}

.scheda-centrale--block {
	margin: -75px;
	padding:  20px 0;
	background: white;
	position: relative;
    z-index: 2;
}


// custom modal for the plant data sheet
.anagraficaModal {
	.modal-title {
		font-weight: 200;
	}
	.list-group-item {
	    color: #818182;
	    background-color: transparent;
	    border-color: #e9ecef;
	}



}
