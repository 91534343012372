.ambiente {
  background-image: url("../images/ambiente_bg.jpg");
  background-size: cover;
  height: 715px;
}

.ambiente__block {
  height: 715px;
  max-width: 100%;
  width: 100%;
  z-index: 10;
}

@media (min-width: 769px) {
  .decommissioning #decomissioning-realizzate,
  .decommissioning #decomissioning-incorso {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.decommissioning .swiper-container {
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
}

.decommissioning .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;*/
}

.decommissioning .swiper-slide .project-counter {
  bottom: 5px;
  right: 5px;
  padding: .25rem .5rem;
  background-color: #eaeaea;
}

.decommissioning .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.decommissioning .swiper-pagination-bullet-active {
  background: white;
}

.decommissioning .swiper-slide-next {
  opacity: 1;
}

/**   old version
.gestione-rifiuti {
	background-image: url("../images/gestione-dei-rifiuti.jpg");
	background-size: cover;
	height: 715px;
}

.gestione-rifiuti__block {
	height: 715px;
	max-width: 100%;
    width: 100%;
    z-index: 10;
}
**/
.gestione-rifiuti {
  background-image: url("../images/gestione-dei-rifiuti-mono.jpg");
  background-size: cover;
  background-position: 50%;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.gestione-rifiuti .card {
  border: 0;
  border-bottom: 5px solid #005170;
}

.gestione-rifiuti .trattamento {
  fill: #00adef;
}

.gestione-rifiuti .recupero .curved_arrow_1 {
  fill: #009bd6;
}

.gestione-rifiuti .recupero .curved_arrow_2 {
  fill: #00adef;
}

.gestione-rifiuti .recupero .curved_arrow_3 {
  fill: #0abbff;
}

.gestione-rifiuti .recupero .bottom_line {
  display: none;
}

.gestione-rifiuti .recupero,
.gestione-rifiuti .stoccaggio,
.gestione-rifiuti .trattamento,
.gestione-rifiuti .gauge {
  width: 120px;
  margin: auto;
}

.gestione-rifiuti #arm,
.gestione-rifiuti #tape,
.gestione-rifiuti #barrier2,
.gestione-rifiuti .circle,
.gestione-rifiuti .knob {
  fill: #8cc3e4;
}

.gestione-rifiuti #Barrier1_1_ {
  fill: #cae3f3;
}

.gestione-rifiuti #barrel1,
.gestione-rifiuti #barrel2,
.gestione-rifiuti #barrel {
  fill: #1a87c9;
}

.gestione-rifiuti .contour {
  fill: #1a87c9;
}

.gestione-rifiuti .level1 {
  fill: #b9dff6;
}

.gestione-rifiuti .level2 {
  fill: #8ccaf0;
}

.gestione-rifiuti .level3 {
  fill: #5fb6ea;
}

.gestione-rifiuti .level4 {
  fill: #32a1e4;
}

.gestione-rifiuti .level5 {
  fill: #1a87c9;
}

.gestione-rifiuti .level6 {
  fill: #14699c;
}

.gestione-rifiuti .card__gestionerifiuti {
  transition: all .5s;
  box-shadow: 0 8px 17px 0 transparent, 0 6px 20px 0 transparent;
}

.gestione-rifiuti .card__gestionerifiuti:hover {
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
}

.gestione-rifiuti .card__gestionerifiuti:hover a {
  color: #14699c;
}

.gestione-rifiuti .card__gestionerifiuti a {
  transition: all .5s;
  color: #868e96;
}

.svg-holder {
  min-height: 130px;
}

.dati-centrale-icon {
  width: 100px;
}

.dati-centrale-icon.atomo #nucleo {
  fill: #60b146;
}

.dati-centrale-icon.atomo #orbite {
  fill: gray;
}

.dati-centrale-icon.recupero .curved_arrow_1 {
  fill: #60b146;
}

.dati-centrale-icon.recupero .curved_arrow_2 {
  fill: #7dc367;
}

.dati-centrale-icon.recupero .curved_arrow_3 {
  fill: #9dd18c;
}

.dati-centrale-icon.recupero .bottom_line {
  fill: none;
  stroke: gray;
  stroke-width: 16;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.dati-centrale-icon.coins .pile_1 {
  fill: #FFFFFF;
  stroke: gray;
  stroke-width: 14;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.dati-centrale-icon.coins .pile_2 {
  fill: #FFFFFF;
  stroke: #60b146;
  stroke-width: 14;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.dati-centrale-icon.coins .pile_3 {
  fill: #FFFFFF;
  stroke: lightgray;
  stroke-width: 14;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.dati-centrale-icon.calendar .calendar_line {
  fill: none;
  stroke: gray;
  stroke-width: 16;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.dati-centrale-icon.calendar .calendar_hook {
  fill: none;
  stroke: gray;
  stroke-width: 16;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.dati-centrale-icon.calendar .dots {
  fill: lightgray;
}

.dati-centrale-icon.calendar .calendar_border {
  fill: #60b146;
}

.dati-centrale-icon.calendar .st0 {
  fill: lightgray;
}

.dati-centrale-icon.calendar .st1 {
  fill: none;
  stroke: gray;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.dati-centrale-icon.calendar .st2 {
  fill: none;
  stroke: gray;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 17.244,36.4039;
}

.dati-centrale-icon.chimney #steam {
  fill: lightgray;
}

.dati-centrale-icon.chimney #ground {
  fill: #60b146;
}

.dati-centrale-icon.chimney #chimney {
  fill: gray;
}

.dati-centrale-icon.arm #tape {
  fill: lightgray;
}

.dati-centrale-icon.arm #box1, .dati-centrale-icon.arm #box2 {
  fill: #60b146;
}

.dati-centrale-icon.arm #arm {
  fill: gray;
}

.dati-centrale-icon.worker {
  width: 110px;
  margin-top: -5px;
}

.dati-centrale-icon.worker .face {
  fill: lightgray;
}

.dati-centrale-icon.worker .overalls {
  fill: #60b146;
}

.dati-centrale-icon.worker .helmet {
  fill: #60b146;
}

.dati-centrale-icon.worker .body {
  fill: gray;
}

.dati-centrale-icon.wallet .euro-symbol {
  fill: #60b146;
}

.dati-centrale-icon.wallet .notes {
  fill: #b3b3b3;
}

.dati-centrale-icon.rifiuti-prodotti {
  width: 120px;
}

.dati-centrale-icon.rifiuti-prodotti .atom-circle,
.dati-centrale-icon.rifiuti-prodotti .bin {
  fill: #60b146;
}

.dati-centrale-icon.rifiuti-prodotti .radiation {
  fill: gray;
}

.dati-centrale-icon.rifiuti-prodotti .soil {
  fill: gray;
}

.dati-centrale-icon.brownfield .leaves {
  fill: #60b146;
}

.dati-centrale-icon.brownfield .globe {
  fill: gray;
}

.dati-centrale-icon.brownfield .branches {
  fill: lightgray;
}

.dati-centrale-icon.recycle {
  width: 110px;
}

.dati-centrale-icon.recycle .recycle {
  fill: #60b146;
}

.dati-centrale-icon.recycle .wheels,
.dati-centrale-icon.recycle .lorry {
  fill: #b3b3b3;
}

.main-image__blocks {
  height: 715px;
}

.scheda-centrale {
  background: white;
}

.media-centrali .swiper-container {
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
}

.media-centrali .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically 
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;*/
}

.media-centrali .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.media-centrali .swiper-pagination-bullet-active {
  background: white;
}

.media-centrali .swiper-slide {
  border-radius: 4px;
}

.media-centrali .swiper-slide .image-mask {
  width: 100%;
  /*container-width*/
  height: 380px;
  overflow: hidden;
}

.media-centrali .swiper-slide .image-mask img {
  width: 580px;
  height: 390px;
  object-fit: cover;
}

.media-centrali .swiper-slide-next .image-mask {
  background: transparent;
}

@media (min-width: 769px) {
  .media-centrali .swiper-slide {
    opacity: .25;
  }
  .media-centrali .swiper-slide-next {
    opacity: 1;
  }
}

.decommissioning-news .swiper-container {
  width: 100%;
  padding-bottom: 3rem;
}

.decommissioning-news .swiper-slide-active {
  border-right: 1px solid #d3d3d3;
}

.decommissioning-news .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.decommissioning-news .swiper-pagination-bullet-active {
  background: #60b146;
}

.scheda-centrale {
  background: #fafafa;
}

.scheda-centrale {
  background: white;
}

.scheda-centrale--block {
  margin: -75px;
  padding: 20px 0;
  background: white;
  position: relative;
  z-index: 2;
}

.anagraficaModal .modal-title {
  font-weight: 200;
}

.anagraficaModal .list-group-item {
  color: #818182;
  background-color: transparent;
  border-color: #e9ecef;
}

@media (min-width: 769px) {
  .s-fornitori-centrali {
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 51%, #eaeaea 51%, #eaeaea 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 51%, #eaeaea 51%, #eaeaea 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffffff 0%, #ffffff 51%, #eaeaea 51%, #eaeaea 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='$chiaro',GradientType=1 );
    /* IE6-9 */
  }
}
