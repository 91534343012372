.decommissioning-news {
  .swiper-container {
    width: 100%;
    //height: 100%;
    padding-bottom: 3rem;
  }
	.swiper-slide-active {
		border-right: 1px solid $s-gray;
	}
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
  .swiper-pagination-bullet-active {
    background: $s-green;
  }
}
