/**   old version
.gestione-rifiuti {
	background-image: url("../images/gestione-dei-rifiuti.jpg");
	background-size: cover;
	height: 715px;
}

.gestione-rifiuti__block {
	height: 715px;
	max-width: 100%;
    width: 100%;
    z-index: 10;
}
**/


.gestione-rifiuti {
	background-image: url("../images/gestione-dei-rifiuti-mono.jpg");
	background-size: cover;
	background-position: 50%;
	padding-top: 5rem;
    padding-bottom: 5rem;
    .card {
    	border: 0;
    	border-bottom: 5px solid darken($blue, 25%);

    }
		.trattamento {
			fill: $blue;
		}
		.recupero {
			.curved_arrow_1{
					fill: darken($blue, 5%);
			}
			.curved_arrow_2{
					fill: $blue;
			}
			.curved_arrow_3{
					fill: lighten($blue, 5%);
			}
			.bottom_line {
				display: none;
			}
		}
	.recupero,
	.stoccaggio,
	.trattamento,
	.gauge {
		width: 120px;
		margin: auto;
		//background: lighten(lightgray, 10%);
		//border-radius: 60px;
	}
    #arm,
    #tape,

    #barrier2,
    .circle,
    .knob
     {
    	//fill: darken($blue, 12%);
    	fill: #8cc3e4;

    }

    #Barrier1_1_ {
    	fill: lighten(#8cc3e4, 15%);
    }
    #barrel1,
    #barrel2,
    #barrel {
		//fill: $blue;
		fill: #1a87c9;
    }

    .contour {
    	fill: #1a87c9;
    }


    .level1 {
    	fill: lighten(#1a87c9, 40%);
    }
    .level2 {
		fill: lighten(#1a87c9, 30%);
    }
    .level3 {
		fill: lighten(#1a87c9, 20%);
    }
    .level4 {
		fill: lighten(#1a87c9, 10%);
    }
    .level5 {
		fill: #1a87c9;
    }
    .level6 {
    	fill: darken(#1a87c9, 10%);
    }
		.card__gestionerifiuti {
			transition: all .5s;
			box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0), 0 6px 20px 0 rgba(0, 0, 0, 0);
			&:hover {
				transition: all 2s cubic-bezier(.165, .84, .44, 1);
			  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
				//transform: scale3d(1.006, 1.006, 1);
				a {
					color: darken(#1a87c9, 10%);
				}
			}
			a {
				transition: all .5s;
				color: $secondary;
			}
		}
}
