.media-centrali {
  .swiper-container {
    width: 100%;
    height: 100%;
    padding-bottom: 4rem;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically 
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;*/
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  .swiper-pagination-bullet-active {
    background: white;
  }


  .swiper-slide {
    border-radius: 4px;
    .image-mask {
      width:100%; /*container-width*/
      height: 380px;
      overflow: hidden;
      img {
        width: 580px;
        height: 390px;
        object-fit: cover;
        
      }
    }
  }
  .swiper-slide-next {
    .image-mask {

      background: transparent;

    }
  }
}
@media (min-width: 769px) {
  .media-centrali {
    .swiper-slide {
      opacity: .25;
    }
     .swiper-slide-next {
    opacity: 1;
  }
  }
}