@media (min-width: 769px) { 
    .s-fornitori-centrali {
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 51%, $chiaro 51%, $chiaro 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffffff 0%,#ffffff 51%,$chiaro 51%,$chiaro 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffffff 0%,#ffffff 51%,$chiaro 51%,$chiaro 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='$chiaro',GradientType=1 ); /* IE6-9 */
    }
}

