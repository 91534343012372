@media (min-width: 769px) { 

.decommissioning {
  #decomissioning-realizzate,
  #decomissioning-incorso {
    padding-left: 60px;
    padding-right: 60px;
  }
}
}


.decommissioning {

  .swiper-container {
    width: 100%;
    height: 100%;
    padding-bottom: 4rem;
  }
  .swiper-slide {
    text-align: center;
    //opacity: .2;
    font-size: 18px;
    /* Center slide text vertically
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;*/
    .project-counter {
      bottom:5px;
      right: 5px;
      padding: .25rem .5rem;
      background-color: $chiaro;
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  .swiper-pagination-bullet-active {
    background: white;
  }

  .swiper-slide-next {
    opacity: 1;
  }
}
