.ambiente {
	background-image: url("../images/ambiente_bg.jpg");
	background-size: cover;
	height: 715px;
}

.ambiente__block {
	height: 715px;
	max-width: 100%;
    width: 100%;
    z-index: 10;
}
