.svg-holder {
	min-height: 130px;;
}

.dati-centrale-icon {
	width: 100px;
	&.atomo {
		#nucleo {
			fill: $green;
			}
		#orbite {
			fill: gray;
		}
	}
	&.recupero {
		.curved_arrow_1 {
			fill: $green;
		}
		.curved_arrow_2 {
			fill: lighten($green, 10%);
		}
		.curved_arrow_3 {
			fill: lighten($green, 20%);
		}
		.bottom_line {
			fill:none;
			stroke:gray;
			stroke-width:16;
			stroke-linecap:round;
			stroke-miterlimit:10;
		}
	}
	&.coins {
		.pile_1 {
			fill:#FFFFFF;stroke:gray;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;
		}
		.pile_2 {
			fill:#FFFFFF;stroke:$green;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;
		}
		.pile_3 {
			fill:#FFFFFF;stroke:lightgray;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;
		}
	}
	&.calendar {
		.calendar_line {
			fill:none;
			stroke:gray;
			stroke-width:16;
			stroke-linecap:round;
			stroke-miterlimit:10;
		}
		.calendar_hook{
			fill:none;
			stroke:gray;
			stroke-width:16;
			stroke-linecap:round;
			stroke-miterlimit:10;
		}
		.dots {
			fill: lightgray;
		}
		.calendar_border {
			fill: $green;
		}
		.st0{
			fill:lightgray;
		}
		.st1{fill:none;stroke:gray;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;}
		.st2{fill:none;stroke:gray;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;stroke-dasharray:17.244,36.4039;}
	}
	&.chimney {
		#steam {
			fill: lightgray;
			}
		#ground {
			fill: $green;
		}
		#chimney {
			fill: gray;
		}
	}
	&.arm {
		#tape {
			fill: lightgray;
			}
		#box1, #box2 {
			fill: $green;
		}
		#arm {
			fill: gray;
		}
	}
	&.worker {
		width: 110px;
		margin-top: -5px;
		.face {
			fill: lightgray;
			}
		.overalls {
			fill: $green;
		}
		.helmet {
			fill: $green;
		}
		.body {
			fill: gray;
		}
	}
	&.wallet {
		.euro-symbol {
			fill: $green;
		}
		.notes {
			fill: lighten(gray, 20%);
		}
	}
	&.rifiuti-prodotti {
		width: 120px;
		.atom-circle,
		.bin {
			fill: $green;
		}
		.radiation {
			fill: gray;
		}
		.soil {
			fill: gray;
		}
	}
	&.brownfield {
		.leaves {
			fill: $green;
		}
		.globe {
			fill: gray;
		}
		.branches {
			fill: lightgray;
		}
	}
	&.recycle {
		width: 110px;
		.recycle {
			fill: $green;
		}
		.wheels,
		.lorry {
			fill: lighten(gray, 20%);
		}

	}
}
